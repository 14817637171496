import React, { useEffect, useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { Menu, Grid, Badge, Tooltip, Row } from 'antd'
import IntlMessage from '../util-components/IntlMessage'
import Icon from '../util-components/Icon'
import navigationConfig from 'configs/NavigationConfig'
import { connect } from 'react-redux'
import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE } from 'constants/ThemeConstant'
import utils from 'utils'
import { onMobileNavToggle } from 'redux/actions/Theme'
import { RolesEnum } from 'utils/enum'
import { InfoCircleTwoTone } from '@ant-design/icons'
import { refreshFeaturesFunc } from 'redux/actions/Auth'
import groupedNavConfig from 'configs/GroupedNavConfig'
import { isTrialUser } from 'utils/roleRestrictions'
import './styles.scss'
import { useIntl } from 'react-intl'
import { useQuery } from 'react-query'
import { getCompanyFeatures } from 'services/company'
import { getGuacamoleIntegration } from 'services/integrations'
import { env } from 'configs/EnvironmentConfig'

const { SubMenu } = Menu
const { useBreakpoint } = Grid
// eslint-disable-next-line
const csDemoCompany = [
  'dde35545-4ec6-4b54-be59-49d96792c69a',
  'c49dd55c-b04e-4714-bbfe-11bb47c92cc1',
  '84af2977-4aa5-4356-b3c9-eda04a88ff31',
] // ziv demo-company
function convertToTitleCase(inputString) {
  var words = inputString?.split('-')
  var titleCaseWords = words?.map(function (word) {
    return word?.charAt(0).toUpperCase() + word?.slice(1)
  })
  return titleCaseWords?.join(' ')
}
const setLocale = (isLocaleOn, localeKey) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString()

const setDefaultOpen = (key) => {
  let keyList = []
  let keyString = ''
  if (key) {
    const arr = key.split('-')
    for (let index = 0; index < arr.length; index++) {
      const elm = arr[index]
      index === 0 ? (keyString = elm) : (keyString = `${keyString}-${elm}`)
      keyList.push(keyString)
    }
  }
  return keyList
}

const SideNavContent = (props) => {
  const intl = useIntl()
  const {
    sideNavTheme,
    routeInfo,
    hideGroupTitle,
    localization,
    onMobileNavToggle,
    notificationsCount,
    alertsCount,
    role,
    help,
    companyId,
    navCollapsed,
  } = props

  const history = useHistory()
  // eslint-disable-next-line
  const [isNewDashboard, setIsNewDashboard] = useState()
  // eslint-disable-next-line
  const [isAdvancedPolicy, setIsAdvancePolicy] = useState(undefined)
  const [guacamole, setGuacamole] = useState()
  const isMobile = !utils.getBreakPoint(useBreakpoint()).includes('lg')
  const closeMobileNav = () => {
    if (isMobile) {
      onMobileNavToggle(false)
    }
  }
  // eslint-disable-next-line
  const { isLoading: companyFeatureLoading, isFetching: companyFeatureFetching } = useQuery(
    'company-feature-in-menu',
    () => getCompanyFeatures(companyId),
    {
      refetchOnWindowFocus: false,
      notifyOnStatusChange: true,
      enabled: companyId ? true : false,
      onSuccess: (data) => {
        if (data?.features?.useAdvancedPolicies) setIsAdvancePolicy(true)
      },
    },
  )
  // eslint-disable-next-line
  const {} = useQuery('guacamole-integration', () => getGuacamoleIntegration(), {
    onSuccess: (data) => {
      if (data) setGuacamole(data)
    },
  })

  const location = useLocation()
  useEffect(() => {
    if (location) {
      const locationName = convertToTitleCase(location?.pathname?.split('/app/')[1])
      window.document.title = `SURF - ${locationName}`
    }
  }, [location])

  if (!role) return <></>

  return (
    <div className="____menu__container">
      <Menu
        theme={sideNavTheme === SIDE_NAV_LIGHT ? 'light' : 'dark'}
        mode="inline"
        style={{ height: '100%', borderRight: 0 }}
        defaultSelectedKeys={[routeInfo?.key]}
        defaultOpenKeys={setDefaultOpen(routeInfo?.key)}
        className={hideGroupTitle ? 'hide-group-title' : ''}
      >
        {
          // hide group name on collapse
          !navCollapsed && (
            <div style={{ padding: '25px', color: '#7B8FA1', width: '80%' }}>
              {' '}
              {intl.formatMessage({ id: 'general.monitor' })}
            </div>
          )
        }
        {groupedNavConfig[0]
          .filter((menu) => {
            /* NOTE : Restrict Message for trialUser until supported by EXT */
            if (menu.isCompanyLimitedView && env.IS_JIRAN) return null
            if (menu.key === 'messages' && isTrialUser()) return null
            if (role === RolesEnum.Viewer && menu.isViewerRestricted) {
              return null
            }
            return !menu.isRestricted || role === RolesEnum.ProductOwner
          })
          .map((menu) =>
            menu.submenu.length > 0 ? (
              <SubMenu
                icon={menu.icon ? <Icon type={menu?.icon} /> : null}
                key={menu.key}
                title={setLocale(localization, menu.title)}
              >
                {menu.submenu
                  .filter((menu) => {
                    if (menu.isCompanyLimitedView && env.IS_JIRAN) return null
                    if (menu.key === 'malware-logs' && isTrialUser()) return null
                    return !isNewDashboard
                      ? !menu.isRestricted || role === RolesEnum.ProductOwner
                      : menu
                  })
                  .map((subMenuFirst) =>
                    subMenuFirst.submenu.length > 0 ? (
                      <SubMenu
                        style={{ fontWeight: 500 }}
                        icon={subMenuFirst.icon ? <Icon type={subMenuFirst?.icon} /> : null}
                        key={subMenuFirst.key}
                        title={setLocale(localization, subMenuFirst.title)}
                      >
                        {subMenuFirst.submenu
                          .filter((menu) => {
                            if (menu.isCompanyLimitedView && env.IS_JIRAN) return null
                            return !menu.isRestricted || role === RolesEnum.ProductOwner
                          })
                          .map((subMenuSecond) => (
                            <Menu.Item key={subMenuSecond.key}>
                              {subMenuSecond.icon ? <Icon type={subMenuSecond?.icon} /> : null}
                              <span>{setLocale(localization, subMenuSecond.title)}</span>
                              <Link onClick={() => closeMobileNav()} to={subMenuSecond.path} />
                            </Menu.Item>
                          ))}
                      </SubMenu>
                    ) : (
                      <Menu.Item key={subMenuFirst.key}>
                        {subMenuFirst.icon ? <Icon type={subMenuFirst.icon} /> : null}
                        <span>{setLocale(localization, subMenuFirst.title)}</span>
                        <Link onClick={() => closeMobileNav()} to={subMenuFirst.path} />
                      </Menu.Item>
                    ),
                  )}
              </SubMenu>
            ) : (
              <Menu.Item key={menu.key} id={menu.key}>
                <Row
                  justify="space-between"
                  align="bottom"
                  onClick={() => {
                    closeMobileNav()
                    let path = menu.path
                    if (menu.isCompanyLimitedView && env.IS_JIRAN) return null
                    path && history.push(path)
                  }}
                >
                  <div>
                    {menu.icon ? <Icon type={menu?.icon} /> : null}
                    <span>{setLocale(localization, menu?.title)}</span>
                  </div>
                  <div>
                    {menu.key === 'notifications' && (
                      <Badge className="mr-2" count={notificationsCount} />
                    )}
                    {menu.key === 'alert' && <Badge className="mr-2" count={alertsCount} />}
                    {help && (
                      <Tooltip
                        title={`Description of the ${menu.key}`}
                        color="white"
                        arrowPointAtCenter
                        placement="right"
                        overlayInnerStyle={{ border: '#4f738b solid 2px', color: '#365467' }}
                      >
                        <InfoCircleTwoTone />
                      </Tooltip>
                    )}
                  </div>
                </Row>
              </Menu.Item>
            ),
          )}
        {
          // hide group name on collapse
          !navCollapsed && (
            <div
              className="mt-3"
              style={{
                padding: '25px',
                color: '#7B8FA1',
                width: '80%',
                display: role?.includes(RolesEnum.Viewer) && 'none',
              }}
            >
              {intl.formatMessage({ id: 'general.management' })}
            </div>
          )
        }
        {groupedNavConfig[1]
          .filter((menu) => {
            // for displaying advanced-policies only to ziv demo company
            if (
              ['advanced-policies', 'groups-advanced'].includes(menu.key) &&
              !csDemoCompany.includes(companyId)
            ) {
              return null
            }
            if (menu.isCompanyLimitedView && env.IS_JIRAN) return null
            if (menu.key === 'policies-advanced-rule' && !isAdvancedPolicy) return null
            if (menu.key === 'policies' && isAdvancedPolicy) return null
            if (menu.isViewerRestricted && role === RolesEnum.Viewer) {
              return null
            }
            if (menu.key === 'guacamole' && !guacamole) return null
            return (
              !menu.isRestricted ||
              !menu.isViewerRestricted ||
              role === RolesEnum.ProductOwner ||
              (role === 'SuperAdmin' && menu.key === 'users-and-groups')
            )
          })
          .map((menu) =>
            menu.submenu.length > 0 ? (
              <SubMenu
                icon={menu.icon ? <Icon type={menu?.icon} /> : null}
                key={menu.key}
                title={setLocale(localization, menu.title)}
              >
                {menu.submenu
                  .filter((menu) => {
                    if (menu.isCompanyLimitedView && env.IS_JIRAN) return null
                    if (menu.key === 'malware-logs' && isTrialUser()) return null
                    if (menu.key === 'malware-protection' && isTrialUser()) return null
                    return !isNewDashboard
                      ? !menu.isRestricted || role === RolesEnum.ProductOwner
                      : menu
                  })
                  .map((subMenuFirst) =>
                    subMenuFirst.submenu.length > 0 ? (
                      <SubMenu
                        style={{ fontWeight: 500 }}
                        icon={subMenuFirst.icon ? <Icon type={subMenuFirst?.icon} /> : null}
                        key={subMenuFirst.key}
                        title={setLocale(localization, subMenuFirst.title)}
                      >
                        {subMenuFirst.submenu
                          .filter((menu) => !menu.isRestricted || role === RolesEnum.ProductOwner)
                          .map((subMenuSecond) => (
                            <Menu.Item key={subMenuSecond.key}>
                              {subMenuSecond.icon ? <Icon type={subMenuSecond?.icon} /> : null}
                              <span>{setLocale(localization, subMenuSecond.title)}</span>
                              <Link onClick={() => closeMobileNav()} to={subMenuSecond.path} />
                            </Menu.Item>
                          ))}
                      </SubMenu>
                    ) : (
                      <Menu.Item key={subMenuFirst.key}>
                        {subMenuFirst.icon ? <Icon type={subMenuFirst.icon} /> : null}
                        <span>{setLocale(localization, subMenuFirst.title)}</span>
                        <Link onClick={() => closeMobileNav()} to={subMenuFirst.path} />
                      </Menu.Item>
                    ),
                  )}
              </SubMenu>
            ) : (
              <Menu.Item key={menu.key} id={menu.key}>
                <Row
                  justify="space-between"
                  align="bottom"
                  onClick={() => {
                    closeMobileNav()
                    let path = menu.path
                    path && history.push(path)
                  }}
                >
                  <div>
                    {menu.icon ? <Icon type={menu?.icon} /> : null}
                    <span>{setLocale(localization, menu?.title)}</span>
                  </div>
                  <div>
                    {menu.key === 'notifications' && (
                      <Badge className="mr-2" count={notificationsCount} />
                    )}
                    {help && (
                      <Tooltip
                        title={`Description of the ${menu.key}`}
                        color="white"
                        arrowPointAtCenter
                        placement="right"
                        overlayInnerStyle={{ border: '#4f738b solid 2px', color: '#365467' }}
                      >
                        <InfoCircleTwoTone />
                      </Tooltip>
                    )}
                  </div>
                </Row>
              </Menu.Item>
            ),
          )}
      </Menu>
    </div>
  )
}

const TopNavContent = (props) => {
  const { topNavColor, localization } = props
  return (
    <Menu mode="horizontal" style={{ backgroundColor: topNavColor }}>
      {navigationConfig.map((menu) =>
        menu.submenu.length > 0 ? (
          <SubMenu
            key={menu.key}
            popupClassName="top-nav-menu"
            title={
              <span>
                {menu.icon ? <Icon type={menu?.icon} /> : null}
                <span>{setLocale(localization, menu.title)}</span>
              </span>
            }
          >
            {menu.submenu.map((subMenuFirst) =>
              subMenuFirst.submenu.length > 0 ? (
                <SubMenu
                  key={subMenuFirst.key}
                  icon={subMenuFirst.icon ? <Icon type={subMenuFirst?.icon} /> : null}
                  title={setLocale(localization, subMenuFirst.title)}
                >
                  {subMenuFirst.submenu.map((subMenuSecond) => (
                    <Menu.Item key={subMenuSecond.key}>
                      <span>{setLocale(localization, subMenuSecond.title)}</span>
                      <Link to={subMenuSecond.path} />
                    </Menu.Item>
                  ))}
                </SubMenu>
              ) : (
                <Menu.Item key={subMenuFirst.key}>
                  {subMenuFirst.icon ? <Icon type={subMenuFirst?.icon} /> : null}
                  <span>{setLocale(localization, subMenuFirst.title)}</span>
                  <Link to={subMenuFirst.path} />
                </Menu.Item>
              ),
            )}
          </SubMenu>
        ) : (
          <Menu.Item key={menu.key}>
            {menu.icon ? <Icon type={menu?.icon} /> : null}
            <span>{setLocale(localization, menu?.title)}</span>
            {menu.path ? <Link to={menu.path} /> : null}
          </Menu.Item>
        ),
      )}
    </Menu>
  )
}

const MenuContent = (props) => {
  return props.type === NAV_TYPE_SIDE ? <SideNavContent {...props} /> : <TopNavContent {...props} />
}

const mapStateToProps = ({ theme, auth }) => {
  const { sideNavTheme, topNavColor } = theme
  const { notificationsCount, role, help, companyId, alertsCount } = auth
  return { sideNavTheme, topNavColor, notificationsCount, role, help, companyId, alertsCount }
}

const mapDispatchToProps = {
  onMobileNavToggle,
  refreshFeaturesFunc,
}
export default connect(mapStateToProps, mapDispatchToProps)(MenuContent)
